import initScrollReveal from './scripts/scrollReveal'
import initTiltEffect from './scripts/tiltAnimation'
import { targetElements, defaultProps } from './data/scrollRevealConfig'

initScrollReveal(targetElements, defaultProps)
initTiltEffect()

const languageChooser = document.querySelector('#language-chooser')

if (localStorage.getItem('tierri-language') === 'pt' && languageChooser.dataset.language === 'en') {
  console.log('Switching to PT')
  window.location.href = './pt.html'
} else if (localStorage.getItem('tierri-language') === 'en' && languageChooser.dataset.language === 'pt') {
  console.log('Switching to EN')
  window.location.href = './'
}

languageChooser.addEventListener('click', (e) => {
  e.preventDefault()
  if (e.target.dataset.language === 'en') {
    console.log('Detected EN')
    localStorage.setItem('tierri-language', 'pt')
    window.location.href = './pt.html'
  } else if (e.target.dataset.language === 'pt') {
    console.log('Detected PT')
    localStorage.setItem('tierri-language', 'en')
    window.location.href = './'
  }
})
